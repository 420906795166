// Make ConfAPI globally available
var ConfAPI = ConfAPI || false;

(function ($) {
  ConfAPI = {
    settings: {
      url: '/index.php/ozracing/jfnetwork/'
    },
    get: function (ressource, params, callback) {
      var api = this;
      params = params != null ? params : {};

      $.post(api.settings.url + ressource, params, function (data) {
        callback(data);
      });
    }
  }
})(jQuery);